// MUI
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  Box,
  Button,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
// React
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
// Features
import { openSignUp } from 'features/auth/authSlice';
import {
  useCreateAncillaryMutation,
  useUpdateAncillaryMutation,
} from 'features/ancillaries/ancillariesAPI';
// Custom Hooks
import { useGetCycleById } from 'features/cycles/hooks';
import { useGetAncillariesByCycleId } from 'features/ancillaries/hooks';
// Helpers
import { DRUG_FACTS, ANCILLARIES } from 'constants';
import moment from 'moment';
import { customToast } from 'helpers/customToast';

const initialStateValues = {
  id: '',
  name: '',
  frequency: '',
  dose: 0,
  date: moment(),
  start_wk: '',
  stop_wk: '',
};

function AncillaryForm({
  handleDialogClose,
  open,
  editing,
  current_ancillary_id,
}) {
  // Redux
  const dispatch = useDispatch();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  // RTK Query
  const user_id = useSelector((state) => state.auth.user_id);
  // Custom Hooks
  const { data: cycle, isLoading: cycle_loading } = useGetCycleById();
  const { data: ancillaries, isLoading: ancillaries_loading } =
    useGetAncillariesByCycleId();
  const {
    id: cycle_id,
    weeks: cycle_weeks,
    start_date: cycle_start_date,
  } = cycle || {};

  const [createAncillary, { isLoading: createAncillaryLoading }] =
    useCreateAncillaryMutation();
  const [updateAncillary, { isLoading: updateAncillaryLoading }] =
    useUpdateAncillaryMutation();
  // Local State
  const [allVals, setAllVals] = useState(initialStateValues);
  const weeks = [...Array(cycle_weeks).keys()].map((i) => i + 1);
  const [xs, sm] = [12, 4];
  const { id, name, frequency, dose, date, start_wk, stop_wk } = allVals;
  const [og_ancillary, setOgAncillary] = useState(null);

  useEffect(() => {
    if (editing && current_ancillary_id) {
      const ancillary = ancillaries.find(
        (ancillary) => ancillary.id === current_ancillary_id
      );
      const { createdAt, updatedAt, __v, ...ancillary_filtered } = ancillary;
      setOgAncillary(ancillary_filtered);
      setAllVals((state) => ({
        ...state,
        ...ancillary_filtered,
      }));
    } else {
      setAllVals(initialStateValues);
    }
  }, [editing, current_ancillary_id, ancillaries, open]);

  const handleChange = (e) => {
    setAllVals({ ...allVals, [e.target.name]: e.target.value });
  };

  const handleStartWkChange = (e) => {
    if (e.target?.value && stop_wk && e.target.value > stop_wk) {
      setAllVals({ ...allVals, [e.target.name]: e.target.value, stop_wk: '' });
    } else {
      setAllVals({ ...allVals, [e.target.name]: e.target.value });
    }
  };

  const handleDosageChange = (e) => {
    // Optional: strip out anything that's not digits or '.'
    // Then check the decimal portion
    const isValid = /^(\d+(\.\d{0,2})?)?$/.test(e.target.value);

    if (isValid) {
      setAllVals({
        ...allVals,
        [e.target.name]:
          e.target.value >= 0
            ? e.target.value
            : e.target.value.replace(/\D/g, ''),
      });
    }
  };

  const handleDateChange = (date) => {
    setAllVals({ ...allVals, date: date });
  };

  const resetState = () => {
    setAllVals(initialStateValues);
  };

  const cancelForm = () => {
    handleDialogClose();
    resetState();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!is_authenticated) {
      handleDialogClose();
      dispatch(openSignUp('create_update_ancillary'));
      customToast('info', 'Please Sign Up or Login to add Ancillaries', 5000);
      return;
    }

    // Validation
    if (!cycle_id) {
      customToast('error', 'No Cycle ID');
      return;
    } else if (frequency === 0 && !date) {
      customToast('error', 'No Date');
      return;
    } else if (frequency !== 0 && (start_wk === '' || stop_wk === '')) {
      customToast('error', 'Missing Weeks');
      return;
    } else if (frequency !== 0 && stop_wk < start_wk) {
      customToast('error', 'Stop Week is less than Start Week');
      return;
    }

    let newVals;

    // Set Weeks / Date dependently
    if (frequency === 0) {
      newVals = { ...allVals, start_wk: 1, stop_wk: 1 };
    } else {
      newVals = { ...allVals, date: cycle_start_date };
    }

    if (editing && id && shallowEqual(newVals, og_ancillary)) {
      //compare newVals to og_ancillary
      //if they are the same, return
      handleDialogClose();
      customToast('info', 'No Changes');
      return;
    } else if (editing && id) {
      updateAncillary({ ...newVals, cycle_id, id, updated_at: moment() });
      cancelForm();
    } else {
      handleDialogClose();
      createAncillary({ ...newVals, cycle_id, user_id });
    }
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <form onSubmit={onSubmit} className='drug-form'>
        <DialogTitle sx={{ paddingBottom: 0 }}>
          {editing ? `Editing Ancillary` : 'Add New Ancillary'}
        </DialogTitle>
        <Grid container spacing={1} justifyContent='center' py={1} px={2}>
          <Grid item xs={xs} sm={6}>
            <FormControl variant='filled' fullWidth>
              <InputLabel>Name</InputLabel>
              <Select
                required
                name='name'
                value={name}
                label='Name'
                onChange={handleChange}>
                {Object.entries(ANCILLARIES).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={xs} sm={6}>
            <FormControl required fullWidth>
              <TextField
                variant='filled'
                label='Dosage'
                name='dose'
                placeholder='Dosage'
                value={dose}
                onChange={handleDosageChange}
                inputProps={{
                  min: 0,
                  max: 100000,
                  onInput: (value) => 69,
                  inputMode: 'decimal',
                  pattern: '^(?!0+(?:\\.0+)?$)\\d+(\\.\\d{1,2})?$', // This regex allows optional decimal values
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {name ? ANCILLARIES[name]['units'] : ''}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={xs} sm={sm}>
            <FormControl variant='filled' fullWidth>
              <InputLabel>Frequency</InputLabel>
              <Select
                required
                name='frequency'
                value={frequency}
                label='Frequency'
                onChange={handleChange}>
                {Object.entries(DRUG_FACTS.Frequency).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {frequency === 0 ? (
            <Grid item xs={xs} sm={sm * 2}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    required={frequency && frequency === 0 ? true : false}
                    name='date'
                    label='Date'
                    inputFormat='MM/DD/YYYY'
                    value={date}
                    onChange={(value) => handleDateChange(value)}
                    renderInput={(params) => (
                      <TextField variant='filled' {...params} />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={xs / 2} sm={sm}>
              <FormControl variant='filled' fullWidth>
                <InputLabel>Start Week</InputLabel>
                <Select
                  required={frequency && frequency !== 0 ? true : false}
                  name='start_wk'
                  value={start_wk}
                  label='start_wk'
                  onChange={handleStartWkChange}>
                  {weeks.map((i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {frequency === 0 ? (
            <></>
          ) : (
            <Grid item xs={xs / 2} sm={sm}>
              <FormControl variant='filled' fullWidth>
                <InputLabel>Stop Week</InputLabel>
                <Select
                  required={frequency && frequency !== 0 ? true : false}
                  name='stop_wk'
                  value={stop_wk}
                  label='stop_wk'
                  onChange={handleChange}>
                  {weeks.map((i) => {
                    if (!start_wk || i >= start_wk) {
                      return (
                        <MenuItem key={i} value={i}>
                          {i}
                        </MenuItem>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 1 }}>
          <Button variant='outlined' onClick={cancelForm}>
            Cancel
          </Button>
          <Box sx={{ width: '5px' }}></Box>
          <Button variant='contained' type='submit'>
            {editing ? 'Update Ancillary' : 'Create Ancillary'}
          </Button>
        </Box>
      </form>
    </Dialog>
  );
}

export default AncillaryForm;
