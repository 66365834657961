// React
import { useSelector } from 'react-redux';
// RTK Query API
import { useGetPeptidesByCycleIdQuery } from 'features/peptides/peptidesAPI';
// Redux
import { selectCycleInfo } from 'features/cycles/cyclesSlice';

// Custom Hook wrapper around useGetPeptidesByCycleIdQuery
export const useGetPeptidesByCycleId = () => {
  const { active_cycle_id } = useSelector(selectCycleInfo);
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  const no_auth_peptides = useSelector(
    (state) => state.peptides.no_auth_peptides
  );

  const {
    data: peptides,
    isSuccess,
    isLoading,
    isFetching,
  } = useGetPeptidesByCycleIdQuery(active_cycle_id, {
    skip: !is_authenticated || !active_cycle_id,
  });

  return {
    data: is_authenticated ? peptides : no_auth_peptides,
    isSuccess: is_authenticated ? isSuccess : true,
    isLoading,
    isFetching,
  };
};
