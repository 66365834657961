import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { customToast } from '../../helpers/customToast';
import { peptidesAPI } from './peptidesAPI';
import { v4 as uuid_v4 } from 'uuid';
import moment from 'moment';
import { NO_AUTH_PEPTIDES } from 'constants';

const initialState = {
  status: 'idle',
  message: '',
  no_auth_peptides: NO_AUTH_PEPTIDES,
};

export const peptidesSlice = createSlice({
  name: 'peptides',
  initialState,
  reducers: {
    reset: (state) => initialState,
    createPeptide: (state, action) => {
      state.no_auth_peptides.push(action.payload);
    },
    updatePeptide: (state, action) => {
      const index = state.no_auth_peptides.findIndex(
        (dose) => dose.id === action.payload.id
      );
      state.no_auth_peptides[index] = action.payload;
    },
    deletePeptide: (state, action) => {
      state.no_auth_peptides = state.no_auth_peptides.filter(
        (dose) => dose.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        peptidesAPI.endpoints.createPeptide.matchFulfilled,
        () => {
          customToast('success', 'Peptide created successfully');
          window.dataLayer.push({
            event: 'peptide_created',
            category: 'CRUD',
            table: 'Peptides',
            action: 'CREATE',
            auth: true,
          });
        }
      )
      .addMatcher(
        peptidesAPI.endpoints.updatePeptide.matchFulfilled,
        () => {
          customToast('success', 'Peptide updated successfully');
          window.dataLayer.push({
            event: 'peptide_updated',
            category: 'CRUD',
            table: 'Peptides',
            action: 'UPDATE',
            auth: true,
          });
        }
      )
      .addMatcher(
        peptidesAPI.endpoints.deletePeptide.matchFulfilled,
        () => {
          customToast('success', 'Peptide deleted successfully');
          window.dataLayer.push({
            event: 'peptide_deleted',
            category: 'CRUD',
            table: 'Peptides',
            action: 'DELETE',
            auth: true,
          });
        }
      );
  },
});

export const { reset, createPeptide, updatePeptide, deletePeptide } =
  peptidesSlice.actions;
export default peptidesSlice.reducer;
