// ------------------ INITIAL SETUP ------------------

// Importing the necessary data and libraries for drug information and date manipulation.
import { DRUGS, PEPTIDES } from '../constants';
import moment from 'moment';

// visx data format
// const cityTemperature: CityTemperature[] = [
//   {
//     date: '20111001',
//     'New York': '63.4',
//     'San Francisco': '62.7',
//     Austin: '72.2',
//   },
//   ...
// ];

// Set up some constants for calculation precision and initialize a few global variables.
let half_lives = [];
let precision = 4;
let display_precision = 2;
let max_release = 0;

// ------------------ CORE CALCULATIONS ------------------

// Function to compute the remaining effective dose after a given number of days based on the drug's decay rate.
const half_life = (days, effective_dose, decay) => {
  return effective_dose * Math.E ** (-days * decay);
};

// Function to compute the drug's release rate for a given day.
const releaseRate = (days, dose, hl_days, percent_active) => {
  let decay = Math.LN2 / hl_days;
  return decay * half_life(days, dose * percent_active, decay);
};

// Create an array representing the release rate of the drug for each day of the cycle.
const generateHalfLifeArray = (
  full_name,
  dose,
  step = 1,
  cycle_length = 140
) => {
  let arr = [];
  const hl_days =
    DRUGS[full_name]?.half_life_days || PEPTIDES[full_name].half_life_days;
  const percent_active =
    DRUGS[full_name]?.dose_percentage_active ||
    PEPTIDES[full_name].dose_percentage_active;

  for (let days = 0; days < cycle_length; days += step) {
    let rl_rate = +releaseRate(days, dose, hl_days, percent_active).toFixed(
      precision
    );
    if (rl_rate > 0) {
      arr.push(rl_rate);
    } else {
      break;
    }
  }
  return arr;
};

// Recursive function to compute the cumulative dose of the drug up to a certain day.
// It considers multiple administrations (shots) of the drug and their decay.
const computeSumAtIDays = (gaps, shots, days) => {
  if (shots === 0 || days < 0) {
    return 0;
  } else if (days >= half_lives.length) {
    return computeSumAtIDays(gaps, shots - 1, days - gaps[shots % gaps.length]);
  } else {
    return (
      half_lives[days] +
      computeSumAtIDays(gaps, shots - 1, days - gaps[shots % gaps.length])
    );
  }
};

// Function to compute the offset from the cycle start to either a specific date or based on a starting week.
export const computeOffset = (
  cycle_start_date,
  start_wk,
  frequency,
  date,
  step
) => {
  let days_diff = 0;

  // Ensure cycle_start_date is a Moment.js object
  cycle_start_date = moment(cycle_start_date);

  if (frequency === 0) {
    date = moment(date).startOf('day');
    days_diff = date.diff(cycle_start_date, 'days');
    return days_diff / step;
  } else if (frequency === 69) {
    // MWF case: Calculate difference to next Monday
    const dayOfWeek = cycle_start_date.day(); // 0-Sunday, 1-Monday, ..., 6-Saturday
    const daysToNextMonday = (7 - dayOfWeek + 1) % 7; // Calculate days to the next Monday

    // Adjust the start_wk based on daysToNextMonday
    return ((start_wk - 1) * 7 + daysToNextMonday) / step;
  } else {
    // For all other frequencies, just use the cycle_start_date as anchor
    return ((start_wk - 1) * 7) / step;
  }
};

// ------------------ GRAPH DATA GENERATION ------------------

// Produce the data needed to plot the graph. It iterates over each time step and calculates the dose data for each drug.
const generateGraphData = (
  cycle_start_date,
  cycles_by_drug,
  total_steps,
  step
) => {
  let graph_data = [];
  for (let i = 0; i < total_steps; i += 1) {
    let data_point = {};
    for (const compound of Object.keys(cycles_by_drug)) {
      if (cycles_by_drug[compound].length > 1) {
        // build each cycle, and total
        let compound_total = 0;
        for (const { cycle, name, ester, id } of cycles_by_drug[compound]) {
          const full_name = ester ? `${name}-${ester}` : name;
          data_point[full_name + '_' + id] = cycle[i].toString();
          compound_total += cycle[i];
        }
        data_point[compound] = compound_total.toFixed(display_precision);
      } else {
        // if only one cycle for compound, build that only
        const { cycle, ...cycle_metadata } = cycles_by_drug[compound][0];
        data_point[compound] = cycle[i].toString();
      }
    }
    for (let key in data_point) {
      max_release = Math.max(max_release, data_point[key]);
    }
    graph_data.push({
      date: moment(cycle_start_date)
        .startOf('day')
        .add(i * step, 'days')
        .format('MM/DD/YYYY'),
      days: (i * step).toFixed(1),
      ...data_point,
    });
  }
  // console.log('graph_data', graph_data);
  return [graph_data, max_release];
};

// Main function to generate graph data for the drug cycles.
// For each drug, it computes the offset, determines number of administrations (shots), calculates the cumulative dose, and stores the results.
export const roidCalc = (
  drugs = [],
  cycle_start_date = moment(),
  cycle_length = 140,
  step = 0.5
) => {
  if (drugs.length === 0) return [];
  max_release = 0;
  let cycles_by_drug = {};
  let total_steps = cycle_length / step;

  drugs.forEach((drug) => {
    const { id, name, ester, dose, frequency, start_wk, stop_wk, date } = drug;
    let offset = computeOffset(
      cycle_start_date,
      start_wk,
      frequency,
      date,
      step
    );

    if (offset < total_steps) {
      let cycle = [];
      const full_name = ester ? `${name}-${ester}` : name;
      half_lives = generateHalfLifeArray(full_name, dose, step, cycle_length);
      let shots;
      if (frequency === 69) {
        shots = 3 * (stop_wk - start_wk + 1); // 3 doses every week
      } else {
        shots =
          frequency === 0
            ? 1
            : Math.round(((stop_wk - start_wk + 1) * 7) / frequency);
      }

      for (let days = 0; days < offset; days += 1) {
        cycle.push(0);
      }

      let gaps;
      if (frequency === 69) {
        gaps = [2, 3, 2].map((gap) => Math.round(gap / step));
      } else {
        gaps = Array(shots)
          .fill(frequency)
          .map((gap) => Math.round(gap / step));
      }

      for (let days = 0; days < total_steps - offset; days += 1) {
        let sum = +computeSumAtIDays(gaps, shots, days).toFixed(
          display_precision
        );
        cycle.push(sum);
      }

      if (name in cycles_by_drug) {
        cycles_by_drug[name].push({ ...drug, cycle });
      } else {
        cycles_by_drug[name] = [{ ...drug, cycle }];
      }
    }
  });

  return generateGraphData(cycle_start_date, cycles_by_drug, total_steps, step);
};
