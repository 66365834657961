import React from 'react';
import { useSelector } from 'react-redux';
import { lighterColorHash, desaturateColor } from 'helpers/colorHash';
import { selectDashboardInfo } from 'features/dashboard/dashboardSlice';
import { curveMonotoneX } from '@visx/curve';
import { LineSeries } from '@visx/xychart';

const DoseLines = ({ roidCalcData }) => {
  const { menu_selected } = useSelector(selectDashboardInfo);
  let lines = [];
  if (roidCalcData.length === 0) return null;
  const { date, days, ...compounds } = roidCalcData[0];

  for (const key in compounds) {
    // Only push an aggregate (child) compound line series if the menu selected is COMPOUNDS
    // or if the key does not include an underscore, meaning it's a parent compound
    // Example: 'Testosterone' is a parent compound, 'Testosterone_Cypionate' is a child dose
    // Example - compounds = {Testosterone-Cypionate_2166866a-11d1-4ca4-8a4f-80f2c9795006: '19.13',
    // Testosterone-Propionate_61216f77-a6bb-4099-a7bc-7376a7754fe9: '0', Testosterone: '19.13'}
    (!key.includes('_') || menu_selected === 'COMPOUNDS') &&
      lines.push(
        <LineSeries
          key={key}
          dataKey={key}
          data={roidCalcData}
          xAccessor={(d) => d.days}
          yAccessor={(d) =>
            d[key] === '0' || d[key] === '0.00' ? 'null' : d[key]
          }
          curve={curveMonotoneX}
          // If the menu selected is COMPOUNDS, desaturate the children doses by 20%
          colorAccessor={(d) =>
            menu_selected === 'COMPOUNDS'
              ? lighterColorHash(key.split('_')[0].split('-')[0])
              : desaturateColor(lighterColorHash(key), 20)
          }
          strokeWidth={
            key.includes('_') || menu_selected !== 'COMPOUNDS' ? 1 : 2
          }
          opacity={menu_selected === 'COMPOUNDS' ? 1 : 0.5}
        />
      );
  }

  return lines;
};

export default DoseLines;
