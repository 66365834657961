// React
import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DosesTooltip,
  DoseLines,
  DoseGlyph,
  AncillaryLines,
  AncillariesTooltip,
  ancillariesPrepper,
  PeptideLines,
  PeptideDots,
  peptidesPrepper,
  BloodLines,
  BloodGlyph,
  BloodsTooltip,
  bloodsPrepper,
  PeptidesTooltip,
} from 'components';
// Features
import { selectDashboardInfo } from 'features/dashboard/dashboardSlice';
import { selectBloodsInfo } from 'features/bloods/bloodsSlice';
// Custom Hooks
import { useGetDosesByCycleId } from 'features/doses/hooks';
import { useGetCycleById } from 'features/cycles/hooks';
import { useGetBloodsByCycleId } from 'features/bloods/hooks';
import { useGetAncillariesByCycleId } from 'features/ancillaries/hooks';
import { useGetPeptidesByCycleId } from 'features/peptides/hooks';
// Helpers
import moment from 'moment';
import { roidCalc } from 'helpers/roid_calc_v2';
// MUI
import { Box, useTheme, CircularProgress } from '@mui/material';
// VisX
import { defaultStyles } from '@visx/tooltip';
import {
  Axis,
  Grid,
  XYChart,
  Tooltip,
  Annotation,
  AnnotationLineSubject,
} from '@visx/xychart';
import { HtmlLabel } from '@visx/annotation';
import { max } from 'date-fns';

const CycleGraph = () => {
  const { menu_selected } = useSelector(selectDashboardInfo);
  const { is_authenticated } = useSelector((state) => state.auth);
  const { hidden_results, hidden_markers } = useSelector(selectBloodsInfo);
  const {
    data: cycle,
    isLoading: cycle_is_loading,
    isFetching: cycle_is_fetching,
  } = useGetCycleById();
  const { start_date, weeks } = cycle || {};
  const {
    data: doses,
    isLoading: doses_is_loading,
    isFetching: doses_is_fetching,
  } = useGetDosesByCycleId();
  const { data: peptides } = useGetPeptidesByCycleId();
  const { data: bloods } = useGetBloodsByCycleId();
  const { data: ancillaries } = useGetAncillariesByCycleId();

  const theme = useTheme();

  const [[roidCalcData, dose_max_release], setRoidCalcData] = useState([[], 0]);

  useEffect(() => {
    if (cycle_is_fetching || doses_is_fetching) {
      return;
    } else if (start_date && weeks && doses?.length > 0) {
      setRoidCalcData(
        roidCalc(doses, moment(start_date).startOf('day'), weeks * 7)
      );
    } else {
      setRoidCalcData([[], 0]);
    }
  }, [start_date, weeks, doses]);

  const [max_blood_levels, bloods_by_marker] = useMemo(
    () => bloodsPrepper(bloods, hidden_results, hidden_markers),
    [bloods, hidden_results, hidden_markers]
  );

  const [
    max_ancillary_levels,
    graph_data_by_ancillary,
    ancillaries_overlap = {},
  ] = useMemo(
    () => ancillariesPrepper(ancillaries, start_date, weeks),
    [ancillaries, start_date, weeks]
  );

  const [max_peptide_levels, graph_data_by_peptide, peptides_overlap = {}] =
    useMemo(
      () => peptidesPrepper(peptides, start_date, weeks),
      [peptides, start_date, weeks]
    );

  const getDaysDiff = useMemo(() => {
    const days_diff = moment()
      .startOf('day')
      .diff(moment(start_date).startOf('day'), 'days');
    return days_diff;
  }, [start_date]);

  const max_level = useMemo(() => {
    return Math.max(
      ...Object.keys(max_blood_levels).map(
        (marker) => max_blood_levels[marker]
      ),
      ...Object.keys(max_ancillary_levels).map(
        (key) => max_ancillary_levels[key]
      ),
      ...Object.keys(max_peptide_levels).map((key) => max_peptide_levels[key])
    );
  }, [max_blood_levels, max_ancillary_levels, max_peptide_levels]);

  const yCeiling = useMemo(() => {
    return dose_max_release ? Math.ceil(dose_max_release / 5) * 5 : max_level;
  }, [dose_max_release, max_level]);

  if (
    doses?.length === 0 &&
    peptides?.length === 0 &&
    bloods?.length === 0 &&
    ancillaries?.length === 0
  ) {
    return;
  } else if (cycle_is_loading || doses_is_loading) {
    return <CircularProgress />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        minHeight: '300px',
        maxHeight: '700px',
      }}>
      <XYChart
        xScale={{ type: 'band' }}
        yScale={{
          type: 'linear',
          domain: [0, yCeiling],
          zero: false,
        }}>
        <Axis
          orientation='bottom'
          label='Date'
          hideTicks
          hideAxisLine
          tickFormat={(val) =>
            moment(start_date).startOf('day').add(val, 'days').format('MMM D')
          }
        />
        {doses?.length > 0 && (
          <Axis
            orientation='left'
            label='Release Rate (mg)'
            hideTicks
            hideAxisLine
          />
        )}
        <Annotation
          datum={{ days: getDaysDiff.toFixed(1) }}
          xAccessor={(d) => d.days}
          yAccessor={(d) => yCeiling}
          containerStyle={{
            background: 'red',
          }}>
          <AnnotationLineSubject
            style={{
              stroke: theme.palette.grey[600],
              strokeWidth: '1px',
              height: '100%',
            }}
          />
          <HtmlLabel
            showBackground={true}
            showAnchorLine={false}
            horizontalAnchor='middle'
            verticalAnchor='end'
            containerStyle={{
              color: theme.palette.grey[600],
              width: 'auto',
              whiteSpace: 'nowrap',
              borderRadius: '0px',
              padding: '2px 0px',
              fontSize: '0.8rem',
              fontFamily: 'Arial',
            }}>
            {`Today (${moment().format('MMM D')})`}
          </HtmlLabel>
        </Annotation>
        <Grid columns={false} numTicks={5} strokeOpacity={0.5} />
        <DoseLines roidCalcData={roidCalcData} />
        {
          {
            ANCILLARIES: (
              <>
                <AncillaryLines
                  max_release={yCeiling}
                  max_ancillary_levels={max_ancillary_levels}
                  graph_data_by_ancillary={graph_data_by_ancillary}
                  overlap={ancillaries_overlap}
                />
                {is_authenticated && (
                  <BloodLines
                    cycle_start_date={start_date}
                    weeks={weeks}
                    max_release={yCeiling}
                    max_blood_levels={max_blood_levels}
                    bloods_by_marker={bloods_by_marker}
                  />
                )}
              </>
            ),
            'PEPTIDES+': (
              <>
                <PeptideDots
                  max_release={yCeiling}
                  max_peptide_levels={max_peptide_levels}
                  graph_data_by_peptide={graph_data_by_peptide}
                  overlap={peptides_overlap}
                />
                {is_authenticated && (
                  <BloodLines
                    cycle_start_date={start_date}
                    weeks={weeks}
                    max_release={yCeiling}
                    max_blood_levels={max_blood_levels}
                    bloods_by_marker={bloods_by_marker}
                  />
                )}
              </>
            ),
            'BLOOD WORK': (
              <BloodLines
                cycle_start_date={start_date}
                weeks={weeks}
                max_release={yCeiling}
                max_blood_levels={max_blood_levels}
                bloods_by_marker={bloods_by_marker}
              />
            ),
          }[menu_selected]
        }

        <Tooltip
          snapTooltipToDatumX
          showVerticalCrosshair
          showSeriesGlyphs
          renderGlyph={({ datum, key }) =>
            ({
              COMPOUNDS: <DoseGlyph datum={datum} data_key={key} />,
              // 'PEPTIDES+': <DoseGlyph datum={datum} data_key={key} />,
              // 'BLOOD WORK': <BloodGlyph datum={datum} data_key={key} />,
            }[menu_selected])
          }
          renderTooltip={({ tooltipData, colorScale }) =>
            ({
              COMPOUNDS: (
                <DosesTooltip
                  tooltipData={tooltipData}
                  colorScale={colorScale}
                />
              ),
              'PEPTIDES+': (
                <PeptidesTooltip
                  cycle_start_date={start_date}
                  tooltipData={tooltipData}
                />
              ),
              ANCILLARIES: (
                <AncillariesTooltip
                  cycle_start_date={start_date}
                  tooltipData={tooltipData}
                />
              ),
              'BLOOD WORK': (
                <BloodsTooltip
                  cycle_start_date={start_date}
                  tooltipData={tooltipData}
                />
              ),
            }[menu_selected])
          }
          style={{
            ...defaultStyles,
            background: 'none',
            boxShadow: 'none',
          }}
        />
      </XYChart>
    </Box>
  );
};

export default CycleGraph;
