import { createSlice } from '@reduxjs/toolkit';
import { customToast } from '../../helpers/customToast';
import { bloodsAPI } from './bloodsAPI';
import { createSelector } from 'reselect';
import { NO_AUTH_BLOODS } from 'constants';

const initialState = {
  all: [],
  hidden_results: {},
  hidden_markers: {},
  no_auth_bloods: NO_AUTH_BLOODS,
};

export const bloodsSlice = createSlice({
  name: 'bloods',
  initialState,
  reducers: {
    clearFilters: (state) => {
      state.hidden_results = {};
      state.hidden_markers = {};
    },
    toggleBlood: (state, { payload }) => {
      if (payload in state.hidden_results) {
        delete state.hidden_results[payload];
      } else {
        state.hidden_results[payload] = true;
      }
    },
    toggleMarker: (state, { payload }) => {
      if (payload in state.hidden_markers) {
        delete state.hidden_markers[payload];
      } else {
        state.hidden_markers[payload] = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(bloodsAPI.endpoints.createBloodResult.matchFulfilled, () => {
        customToast('success', 'Blood Result created successfully');
        window.dataLayer.push({
          event: 'bloods_created',
          category: 'CRUD',
          table: 'Bloods',
          action: 'CREATE',
          auth: true,
        });
      })
      .addMatcher(bloodsAPI.endpoints.updateBloodResult.matchFulfilled, () => {
        customToast('success', 'Blood Result updated successfully');
        window.dataLayer.push({
          event: 'bloods_updated',
          category: 'CRUD',
          table: 'Bloods',
          action: 'UPDATE',
          auth: true,
        });
      })
      .addMatcher(bloodsAPI.endpoints.deleteBloodResult.matchFulfilled, () => {
        customToast('success', 'Blood Result deleted successfully');
        window.dataLayer.push({
          event: 'bloods_deleted',
          category: 'CRUD',
          table: 'Bloods',
          action: 'DELETE',
          auth: true,
        });
      });
  },
});

// Creates a selector that returns the hidden_results and hidden_markers
export const selectBloodsInfo = createSelector(
  (state) => state.bloods.hidden_results,
  (state) => state.bloods.hidden_markers,
  (hidden_results, hidden_markers) => {
    return {
      hidden_results,
      hidden_markers,
    };
  }
);

export const { clearFilters, toggleBlood, toggleMarker } = bloodsSlice.actions;
export default bloodsSlice.reducer;
