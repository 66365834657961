import { STEP } from 'constants';
import { max } from 'date-fns';
// computeOffset = (cycle_start_date, start_wk, frequency, date, step) =>
//    returns # of days from cycle start to first dose
import { computeOffset } from 'helpers/roid_calc_v2';
import moment from 'moment';

// Shared overlap objets
// Tracks peptide overlaps for each day and sum of doses
let overlap_days = {};
let unique_doses = {};

// max_levels is an object with the highest value for each peptide by name
//    ex: { HGH: 10 }
//    This is used to scale the y-axis for each peptide according to it's max value
// ancillary_graph_data is an object with an array of objects for each peptide by name
//    each object in an array has a date and the value for peptide
//    ex: { date: '2021-01-01', T: 1000 } for TSH
//    This keeps the data set for each peptide separate so that we can
//    easily map over each peptide and create a line for each one
export const peptidesPrepper = (
  peptides,
  cycle_start_date,
  weeks,
  hidden_peptides = {}
) => {
  if (peptides?.length) {
    let max_levels = {};
    let graph_arrays_by_peptide = {};
    overlap_days = {};
    unique_doses = {};

    // create a ghost array (bands) with zero values for each day in the cycle
    const total_days = weeks * 7;
    const bands = Array.from(
      { length: Math.ceil(total_days / STEP) + 1 },
      (_, i) => ({
        date: moment(cycle_start_date)
          .add(i * STEP, 'days')
          .format('MM/DD/YYYY'),
        value: 0,
        days: (i * STEP).toFixed(1),
      })
    );
    graph_arrays_by_peptide['BANDS'] = bands;

    peptides.forEach((peptide, i) => {
      const { id, name, dose, date, frequency } = peptide;

      // create the graph data for each peptide
      if (!(name in hidden_peptides)) {
        if (!frequency) {
          let start_moment = moment(date).startOf('day');
          let days = start_moment
            .diff(moment(cycle_start_date).startOf('day'), 'days')
            .toFixed(1);

          // update overlap_days for single dose peptide
          update_overlap(days, name, dose);

          if (graph_arrays_by_peptide[name + '_' + id]) {
            graph_arrays_by_peptide[name + '_' + id].push({
              date: start_moment.format('MM/DD/YYYY'),
              [name]: dose,
              days: days,
            });
          } else {
            graph_arrays_by_peptide[name + '_' + id] = [
              {
                date: moment(date).startOf('day').format('MM/DD/YYYY'),
                [name]: dose,
                days: days,
              },
            ];
          }
        } else {
          graph_arrays_by_peptide[name + '_' + id] = createGraphData(
            peptide,
            cycle_start_date
          );
        }
      }

      // reset the max for the peptide if necessary
      if (name in max_levels) {
        if (dose > max_levels[name]) {
          max_levels[name] = dose;
        }
      } else {
        max_levels[name] = dose;
      }
    });

    let unique_ratios = {};
    Object.keys(unique_doses).forEach((dose) => {
      // for each dose there will be a list of peptides
      Object.keys(unique_doses[dose]).forEach((peptide) => {
        const ratio = dose / max_levels[peptide];
        // if the ratio is in the unique_ratios object, add the peptide to the object
        if (!unique_ratios[ratio]) {
          unique_ratios[ratio] = [peptide];
        } else {
          unique_ratios[ratio].push(peptide);
        }
      });
    });

    Object.entries(unique_ratios).map(([key, ratio]) => {
      if (ratio.length > 1) {
        //sort
        return [key, ratio.sort()];
      }
      return [key, ratio];
    });

    return [
      max_levels,
      graph_arrays_by_peptide,
      { days: overlap_days, ratios: unique_ratios },
    ];
  }
  return [{}, {}, {}];
};

const update_overlap = (days, name, dose) => {
  // keep a count of the duplicate doses for that peptide, that day
  if (!overlap_days[days]) {
    overlap_days[days] = {};
  }
  if (!overlap_days[days][name]) {
    overlap_days[days][name] = { [dose]: 1, sum: dose };
  } else {
    if (overlap_days[days][name][dose]) {
      overlap_days[days][name][dose] += 1;
    } else {
      overlap_days[days][name][dose] = 1;
    }
    overlap_days[days][name].sum += dose;
  }
  // keep a list of peptides at each unique dose
  if (!unique_doses[dose]) {
    unique_doses[dose] = {};
  }
  if (!unique_doses[dose][name]) {
    unique_doses[dose][name] = 1;
  } else {
    unique_doses[dose][name] += 1;
  }
};

// return an array of objects with the date and dose for each step in repeat dose
const createGraphData = (peptide, cycle_start_date) => {
  const { id, name, dose, date, frequency, start_wk, stop_wk } = peptide;

  let graph_data_array = [];
  const offset = computeOffset(
    cycle_start_date,
    start_wk,
    frequency,
    date,
    STEP
  );
  let doses = Math.round(((stop_wk - start_wk + 1) * 7) / frequency);

  for (
    let i = offset;
    i < (doses * frequency) / STEP + offset;
    i += frequency / STEP
  ) {
    const days = (i * STEP).toFixed(1);
    graph_data_array.push({
      date: moment(cycle_start_date)
        .startOf('day')
        .add(i * STEP, 'days')
        .format('MM/DD/YYYY'),
      days: days,
      [name]: dose,
    });
    // update overlap_days for that day, that peptide, that dose
    update_overlap(days, name, dose);
  }

  return graph_data_array;
};
