import { configureStore } from '@reduxjs/toolkit';
import dashboardReducer from 'features/dashboard/dashboardSlice';
import drugReducer from 'features/doses/dosesSlice';
import cycleReducer from 'features/cycles/cyclesSlice';
import authReducer from 'features/auth/authSlice';
import bloodsReducer from 'features/bloods/bloodsSlice';
import ancillariesReducer from 'features/ancillaries/ancillariesSlice';
import peptidesReducer from 'features/peptides/peptidesSlice';
import { api } from './api';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    cycles: cycleReducer,
    drugs: drugReducer,
    bloods: bloodsReducer,
    ancillaries: ancillariesReducer,
    peptides: peptidesReducer,
    api: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});
