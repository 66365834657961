import moment from 'moment';
import { v4 as uuid_v4 } from 'uuid';

export const STEP = 0.5;

export const DRUG_FACTS = {
  Compounds: {
    Testosterone: {
      has_esters: true,
      'Testosterone-Base': {},
      'Testosterone-Acetate': {},
      'Testosterone-Propionate': {},
      'Testosterone-Phenylpropionate': {},
      'Testosterone-Isocaproate': {},
      'Testosterone-Enanthate': {},
      'Testosterone-Cypionate': {},
      'Testosterone-Decanoate': {},
      'Testosterone-Undecanoate': {},
    },
    Trenbolone: {
      has_esters: true,
      'Trenbolone-Acetate': {},
      'Trenbolone-Enanthate': {},
    },
    Masteron: {
      has_esters: true,
      'Masteron-Propionate': {},
      'Masteron-Enanthate': {},
    },
    Nandrolone: {
      has_esters: true,
      'Nandrolone-Phenylpropionate': {},
      'Nandrolone-Decanoate': {},
    },
    Equipoise: { has_esters: false },
    Primobolan: {
      has_esters: true,
      'Primobolan-Oral': {},
      'Primobolan-Injectable': {},
    },
    Boldenone: {
      has_esters: true,
      'Boldenone-Acetate': {},
      'Boldenone-Cypionate': {},
    },
    Dihyrdoboldenone: {
      has_esters: true,
      'Dihyrdoboldenone-Cypionate': {},
    },
    Trestolone: {
      has_esters: true,
      'Trestolone-Base': {},
      'Trestolone-Acetate': {},
      'Trestolone-Enanthate': {},
    },
    Halotestin: { has_esters: false },
    Anadrol: { has_esters: false },
    Dianabol: { has_esters: false },
    Turinabol: { has_esters: false },
    Winstrol: {
      has_esters: true,
      'Winstrol-Oral': {},
      'Winstrol-Injectable': {},
    },
    Anavar: { has_esters: false },
    Superdrol: { has_esters: false },
  },
  Frequency: {
    'Single Dose': 0,
    'Twice a day': 0.5,
    'Every day': 1,
    'Every other day': 2,
    MWF: 69,
    'Every 3 days': 3,
    'Every 3.5 days': 3.5,
    'Every 4 days': 4,
    'Every 5 days': 5,
    'Every 6 days': 6,
    'Every 7 days': 7,
    'Every 10 days': 10,
    'Once every 2 weeks': 14,
    'Once a month': 30,
  },
};

export const DRUGS = {
  'Testosterone-Base': {
    name: 'Testosterone Base',
    nickname: 'TNE',
    // Ref: PubMed 6793232
    half_life_days: 0.083,
    dose_percentage_active: 1,
  },
  'Testosterone-Acetate': {
    name: 'Testosterone Acetate',
    nickname: 'Test-Ace',
    half_life_days: 0.75,
    dose_percentage_active: 0.87,
  },
  'Testosterone-Propionate': {
    name: 'Testosterone Propionate',
    nickname: 'Test-Prop',
    half_life_days: 0.8,
    dose_percentage_active: 0.8,
  },
  'Testosterone-Phenylpropionate': {
    name: 'Testosterone Phenylpropionate',
    nickname: 'Test-Phenyl',
    half_life_days: 1.5,
    dose_percentage_active: 0.66,
  },
  'Testosterone-Isocaproate': {
    name: 'Testosterone Isocaproate',
    nickname: 'Test-Iso',
    half_life_days: 4.0,
    dose_percentage_active: 0.72,
  },
  'Testosterone-Enanthate': {
    name: 'Testosterone Enanthate',
    nickname: 'Test-E',
    half_life_days: 4.5,
    dose_percentage_active: 0.7,
  },
  'Testosterone-Cypionate': {
    name: 'Testosterone Cypionate',
    nickname: 'Test-Cyp',
    half_life_days: 5.0,
    dose_percentage_active: 0.69,
  },
  'Testosterone-Decanoate': {
    name: 'Testosterone Decanoate',
    nickname: 'Test-D',
    half_life_days: 7.5,
    dose_percentage_active: 0.62,
  },
  'Testosterone-Undecanoate': {
    name: 'Testosterone Undecanoate',
    nickname: 'Test-U',
    half_life_days: 20.9,
    dose_percentage_active: 0.61,
  },
  'Trenbolone-Acetate': {
    name: 'Trenbolone Acetate',
    nickname: 'Tren-Ace',
    half_life_days: 1.0,
    dose_percentage_active: 0.87,
  },
  'Trenbolone-Enanthate': {
    name: 'Trenbolone Enanthate',
    nickname: 'Tren-E',
    half_life_days: 4.5,
    dose_percentage_active: 0.7,
  },
  'Masteron-Propionate': {
    name: 'Masteron Propionate',
    nickname: 'Mast-P',
    half_life_days: 0.8,
    dose_percentage_active: 0.8,
  },
  'Masteron-Enanthate': {
    name: 'Masteron Enanthate',
    nickname: 'Mast-E',
    half_life_days: 4.5,
    dose_percentage_active: 0.7,
  },
  'Nandrolone-Phenylpropionate': {
    name: 'Nandrolone Phenylpropionate',
    nickname: 'NPP',
    half_life_days: 1.5,
    dose_percentage_active: 0.67,
  },
  'Nandrolone-Decanoate': {
    name: 'Nandrolone Decanoate',
    nickname: 'Deca',
    half_life_days: 7.5,
    dose_percentage_active: 0.64,
  },
  Equipoise: {
    name: 'Equipoise',
    nickname: 'EQ',
    half_life_days: 14.0,
    dose_percentage_active: 0.61,
  },
  'Primobolan-Oral': {
    name: 'Primobolan Oral',
    nickname: 'Primo-Ace',
    half_life_days: 5 / 24,
    dose_percentage_active: 1,
  },
  'Primobolan-Injectable': {
    name: 'Primobolan Injectable',
    nickname: 'Primo-E',
    half_life_days: 4.5,
    dose_percentage_active: 0.7,
  },
  'Boldenone-Acetate': {
    name: 'Boldenone Acetate',
    nickname: 'Bold-Ace',
    half_life_days: 12 / 24,
    dose_percentage_active: 0.83,
  },
  'Boldenone-Cypionate': {
    name: 'Boldenone Cypionate',
    nickname: 'Bold-Cyp',
    half_life_days: 5.0,
    dose_percentage_active: 0.69,
  },
  'Dihyrdoboldenone-Cypionate': {
    name: 'Dihyrdoboldenone Cypionate',
    nickname: 'DHB-Cyp',
    half_life_days: 5.0,
    dose_percentage_active: 0.69,
  },
  'Trestolone-Base': {
    name: 'Trestolone Base',
    nickname: 'MENT-Base',
    // Ref: PubMed 6793232
    half_life_days: 0.083, // ~2 hours
    dose_percentage_active: 1,
  },
  'Trestolone-Acetate': {
    name: 'Trestolone Acetate',
    nickname: 'MENT-Ace',
    // Ref: PubMed 6793232
    half_life_days: 0.75, // ~18 hours
    dose_percentage_active: 0.87,
  },
  'Trestolone-Enanthate': {
    name: 'Trestolone Enanthate',
    nickname: 'MENT-E',
    // Based on similar ester chain to testosterone enanthate
    half_life_days: 4.5,
    dose_percentage_active: 0.71,
  },
  Halotestin: {
    name: 'Halotestin',
    nickname: 'Halo',
    half_life_days: 7 / 24,
    dose_percentage_active: 1,
  },
  Anadrol: {
    name: 'Anadrol',
    nickname: 'Anadrol',
    half_life_days: 14 / 24,
    dose_percentage_active: 1,
  },
  Dianabol: {
    name: 'Dianabol',
    nickname: 'D-Bol',
    half_life_days: 5 / 24,
    dose_percentage_active: 1,
  },
  Turinabol: {
    name: 'Turinabol',
    nickname: 'T-Bol',
    half_life_days: 16 / 24,
    dose_percentage_active: 1,
  },
  'Winstrol-Oral': {
    name: 'Winstrol Oral',
    nickname: 'Winny-Oral',
    half_life_days: 8 / 24,
    dose_percentage_active: 1,
  },
  'Winstrol-Injectable': {
    name: 'Winstrol Injectable',
    nickname: 'Winny-Depot',
    half_life_days: 1.0,
    dose_percentage_active: 0.87,
  },
  Anavar: {
    name: 'Anavar',
    nickname: 'Var',
    half_life_days: 10 / 24,
    dose_percentage_active: 1,
  },
  Superdrol: {
    name: 'Superdrol',
    nickname: 'Superdrol',
    half_life_days: 10 / 24,
    dose_percentage_active: 1,
  },
};

export const ANCILLARIES = {
  Arimidex: {
    chemical: 'Anastrozole',
    nickname: 'Adex',
    units: 'mg',
  },
  Aromasin: {
    chemical: 'Exemestane',
    nickname: 'Asin',
    units: 'mg',
  },
  Clomid: {
    chemical: 'Clomiphene',
    units: 'mg',
  },
  Enclomiphene: {
    units: 'mg',
  },
  Nolvadex: {
    chemical: 'Tamoxifen',
    nickname: 'Nolva',
    units: 'mg',
  },
  Raloxifene: {
    nickname: 'Ralox',
    units: 'mg',
  },
  Cabergoline: {
    nickname: 'Caber',
    units: 'mg',
  },
  Proviron: {
    chemical: 'Mesterolone',
    units: 'mg',
  },
  HCG: {
    units: 'IU',
    chemical: 'Human Chorionic Gonadotropin',
  },
  HMG: { units: 'IU', chemical: 'Human Menopausal Gonadotropin' },
  HGH: { units: 'IU', chemical: 'Human Growth Hormone' },
  T3: { units: 'mcg', chemical: 'Triiodothyronine' },
  T4: { units: 'mcg', chemical: 'Thyroxine' },
  Cialis: {
    chemical: 'Tadalafil',
    units: 'mg',
  },
  Creatine: { units: 'g' },
  DNP: { units: 'mg', chemical: '2,4-Dinitrophenol' },
  Clenbuterol: { units: 'mcg' },
  DHEA: { units: 'mg', chemical: 'Dehydroepiandrosterone' },
  Pregnenolone: { units: 'mg' },
  Carnitine: { units: 'mg', chemical: 'L-Carnitine' },
  NAC: { units: 'mg', chemical: 'N-Acetyl Cysteine' },
  TUDCA: { units: 'mg', chemical: 'Tauroursodeoxycholic Acid' },
  Metformin: { units: 'mg' },
  Cardarine: { units: 'mg', chemical: 'GW-501516' },
  Stenabolic: { units: 'mg', chemical: 'SR9009' },
  Cerebrolysin: { units: 'mL' },
};

export const PEPTIDES = {
  'MK-677': {
    chemical: 'Ibutamoren',
    nickname: 'MK-677',
    half_life_days: 1.0, // ~24h
    dose_percentage_active: 1,
    units: 'mg',
  },

  'GHRP-2': {
    chemical: 'Growth Hormone Releasing Peptide-2',
    nickname: 'GHRP-2',
    half_life_days: 0.014, // ~20 min
    dose_percentage_active: 1,
    units: 'mcg',
  },

  'GHRP-6': {
    chemical: 'Growth Hormone Releasing Peptide-6',
    nickname: 'GHRP-6',
    half_life_days: 0.021, // ~30 min
    dose_percentage_active: 1,
    units: 'mcg',
  },

  Hexarelin: {
    // chemical = 'Hexarelin' (same as key) => omit
    nickname: 'Hexarelin',
    half_life_days: 0.06, // ~90 min
    dose_percentage_active: 1,
    units: 'mcg',
  },

  Ipamorelin: {
    // chemical = 'Ipamorelin' (same as key) => omit
    nickname: 'Ipamorelin',
    half_life_days: 0.083, // ~2h
    dose_percentage_active: 1,
    units: 'mcg',
  },

  'BPC-157': {
    chemical: 'Body Protection Compound-157',
    nickname: 'BPC-157',
    half_life_days: 0.21, // ~5h
    dose_percentage_active: 1,
    units: 'mcg',
  },

  'TB-500': {
    chemical: 'Thymosin Beta-4 Fragment',
    nickname: 'TB-500',
    half_life_days: 1.0, // ~24h (estimate)
    dose_percentage_active: 1,
    units: 'mg',
  },

  'CJC-1295-DAC': {
    chemical: 'CJC-1295 with DAC',
    nickname: 'CJC-1295-DAC',
    half_life_days: 7.5, // ~7–8 days
    dose_percentage_active: 1,
    units: 'mcg',
  },

  'CJC-1295-noDAC': {
    chemical: 'CJC-1295 w/o DAC (Mod GRF 1-29)',
    nickname: 'CJC-1295-noDAC',
    half_life_days: 0.021, // ~30 min
    dose_percentage_active: 1,
    units: 'mcg',
  },

  Sermorelin: {
    // chemical = 'Sermorelin' (same as key) => omit
    nickname: 'Sermorelin',
    half_life_days: 0.01, // ~15 min
    dose_percentage_active: 1,
    units: 'mcg',
  },

  Tesamorelin: {
    // chemical = 'Tesamorelin' (same as key) => omit
    nickname: 'Tesamorelin',
    half_life_days: 0.083, // ~2h
    dose_percentage_active: 1,
    units: 'mg',
  },

  HGH: {
    chemical: 'Human Growth Hormone',
    nickname: 'HGH',
    half_life_days: 0.021, // ~30 min
    dose_percentage_active: 1,
    units: 'IU',
  },

  'IGF-1': {
    chemical: 'Insulin-like Growth Factor 1',
    nickname: 'IGF-1',
    half_life_days: 0.01, // ~15 min
    dose_percentage_active: 1,
    units: 'mcg',
  },

  'IGF-1-LR3': {
    chemical: 'Insulin-like Growth Factor 1 LR3',
    nickname: 'IGF-1-LR3',
    half_life_days: 1.0, // ~24h
    dose_percentage_active: 1,
    units: 'mcg',
  },

  'IGF-1-DES': {
    chemical: 'Insulin-like Growth Factor 1 DES(1-3)',
    nickname: 'IGF-1-DES',
    half_life_days: 0.017, // ~25 min
    dose_percentage_active: 1,
    units: 'mcg',
  },

  MGF: {
    chemical: 'Mechano Growth Factor',
    nickname: 'MGF',
    half_life_days: 0.005, // ~5 min
    dose_percentage_active: 1,
    units: 'mcg',
  },

  'PEG-MGF': {
    chemical: 'Pegylated Mechano Growth Factor',
    nickname: 'PEG-MGF',
    half_life_days: 1.0, // ~24h
    dose_percentage_active: 1,
    units: 'mcg',
  },

  'Melanotan-II': {
    // chemical = 'Melanotan II' vs key = 'Melanotan-II' => effectively the same name, so omit
    nickname: 'MT-II',
    half_life_days: 1.38, // ~33h
    dose_percentage_active: 1,
    units: 'mcg',
  },
};

export const CADENCES = {
  Frequency: {
    0: 'Single Dose',
    0.5: 'Twice a day',
    1: 'Every day',
    2: 'Every other day',
    69: 'MWF',
    3: 'Every 3 days',
    3.5: 'Every 3.5 days',
    4: 'Every 4 days',
    5: 'Every 5 days',
    6: 'Every 6 days',
    7: 'Every 7 days',
    10: 'Every 10 days',
    14: 'Once every 2 weeks',
    30: 'Once a month',
  },
};

export const DRUG_DOSES = [
  ('Equipoise', 900, 7, 1, 1),
  ('Equipoise', 900, 3.5, 1, 3),
  ('Equipoise', 600, 7, 4, 4),
  ('Testosterone-Cypionate', 250, 7, 1, 1),
  ('Testosterone-Cypionate', 300, 3.5, 1, 4.4),
  ('Testosterone-Cypionate', 300, 7, 4.7, 4.7),
  ('Testosterone-Cypionate', 200, 7, 5, 5),
  ('Testosterone-Cypionate', 300, 3.5, 5.5, 20),
];

export const FULL_NAMES = [
  'Testosterone-Cypionate',
  'Testosterone-Enanthate',
  'Testosterone-Propionate',
  'Testosterone-Suspension',
  'Testosterone-Phenylpropionate',
  'Testosterone-Undecanoate',
  'Trenbolone-Acetate',
  'Trenbolone-Enanthate',
  'Masteron-Propionate',
  'Masteron-Enanthate',
  'Nandrolone-Phenylpropionate',
  'Nandrolone-Decanoate',
  'Boldenone-Acetate',
  'Boldenone-Cypionate',
  'Dihydroboldenone-Cypionate',
  'Equipoise',
  'Primobolan-Oral',
  'Primobolan-Injectable',
  'Halotestin',
  'Anadrol',
  'Dianabol',
  'Turinabol',
  'Winstrol-Oral',
  'Winstrol-Injectable',
  'Anavar',
  'Superdrol',
];

export const BLOOD_UNITS = {
  TEST: 'ng/dL',
  FREE_T: 'ng/dL',
  E2: 'pg/mL',
  SHBG: 'nmol/L',
  DHT: 'ng/dL',
  DHEA_S: 'µg/dL',
  PROLACTIN: 'ng/mL',
  PROGESTERONE: 'ng/mL',
  PREGNENOLONE: 'ng/dL',
  IGF1: 'ng/mL',
  FSH: 'mIU/mL',
  LH: 'mIU/mL',
  TSH: 'mIU/L',
  HEMOGLOBIN: 'g/dL',
  HEMATOCRIT: '%',
  WBC: '10^3/uL',
  RBC: '10^6/uL',
  AST: 'U/L',
  ALT: 'U/L',
  GGT: 'U/L',
  BILIRUBIN: 'mg/dL',
  PSA: 'ng/mL',
  GLUCOSE: 'mg/dL',
  INSULIN: 'pmol/L',
  ALBUMIN: 'g/dL',
  CREATININE: 'mg/dL',
  CHOLESTEROL: 'mg/dL',
  TRIGLYCERIDES: 'mg/dL',
  HDL: 'mg/dL',
  LDL: 'mg/dL',
  'LDL/HDL': 'ratio',
  VITAMIN_D: 'ng/mL',
};

export const NO_AUTH_BLOODS = [
  {
    id: uuid_v4(),
    date: moment().add(4, 'days').format('YYYY-MM-DD HH:mm:ssZ'),
    // created_at: '2022-11-13T19:57:54.239813+00:00',
    // updated_at: null,
    cycle_id: 'c4446de0-6ab5-488b-b085-4dc8547f0e1d',
    user_id: '34364ed9-fca7-4f4b-bd47-64a1061d0a5d',
    physician: null,
    info: null,
    E2: 34,
    TEST: 1245,
    FREE_T: 300,
    IGF1: 250,
    FSH: null,
    LH: null,
    HEMOGLOBIN: null,
    HEMATOCRIT: null,
    WBC: null,
    RBC: null,
    AST: null,
    ALT: null,
    GGT: null,
    BILIRUBIN: null,
    PSA: null,
    GLUCOSE: null,
    INSULIN: null,
    ALBUMIN: null,
    CREATININE: null,
    CHOLESTEROL: null,
    TRIGLYCERIDES: null,
    HDL: null,
    LDL: null,
    'LDL/HDL': null,
    SHBG: null,
    TSH: null,
  },
  {
    id: uuid_v4(),
    date: moment().add(2, 'weeks').format('YYYY-MM-DD HH:mm:ssZ'),
    // created_at: '2022-11-13T19:58:21.943511+00:00',
    // updated_at: '2023-02-03T07:30:29.03+00:00',
    cycle_id: 'c4446de0-6ab5-488b-b085-4dc8547f0e1d',
    user_id: '34364ed9-fca7-4f4b-bd47-64a1061d0a5d',
    physician: null,
    info: null,
    E2: 40,
    TEST: 1700,
    FREE_T: 450,
    IGF1: 400,
    FSH: null,
    LH: null,
    HEMOGLOBIN: null,
    HEMATOCRIT: null,
    WBC: null,
    RBC: null,
    AST: null,
    ALT: null,
    GGT: null,
    BILIRUBIN: null,
    PSA: null,
    GLUCOSE: null,
    INSULIN: null,
    ALBUMIN: null,
    CREATININE: null,
    CHOLESTEROL: null,
    TRIGLYCERIDES: null,
    HDL: null,
    LDL: null,
    'LDL/HDL': null,
    SHBG: null,
    TSH: null,
  },
  {
    id: uuid_v4(),
    date: moment().add(5, 'weeks').format('YYYY-MM-DD HH:mm:ssZ'),
    // created_at: '2022-11-13T19:58:43.13962+00:00',
    // updated_at: '2023-02-03T07:30:22.333+00:00',
    cycle_id: 'c4446de0-6ab5-488b-b085-4dc8547f0e1d',
    user_id: '34364ed9-fca7-4f4b-bd47-64a1061d0a5d',
    physician: null,
    info: null,
    E2: 45,
    TEST: 2000,
    FREE_T: 450,
    IGF1: 350,
    FSH: null,
    LH: null,
    HEMOGLOBIN: null,
    HEMATOCRIT: null,
    WBC: null,
    RBC: null,
    AST: null,
    ALT: null,
    GGT: null,
    BILIRUBIN: null,
    PSA: null,
    GLUCOSE: null,
    INSULIN: null,
    ALBUMIN: null,
    CREATININE: null,
    CHOLESTEROL: null,
    TRIGLYCERIDES: null,
    HDL: null,
    LDL: null,
    'LDL/HDL': null,
    SHBG: null,
    TSH: null,
  },
  {
    id: uuid_v4(),
    date: moment().add(14, 'weeks').format('YYYY-MM-DD HH:mm:ssZ'),
    // created_at: '2022-11-13T19:58:43.13962+00:00',
    // updated_at: '2023-02-03T07:30:22.333+00:00',
    cycle_id: 'c4446de0-6ab5-488b-b085-4dc8547f0e1d',
    user_id: '34364ed9-fca7-4f4b-bd47-64a1061d0a5d',
    physician: null,
    info: null,
    E2: 30,
    TEST: 2500,
    FREE_T: 300,
    IGF1: 300,
    FSH: null,
    LH: null,
    HEMOGLOBIN: null,
    HEMATOCRIT: null,
    WBC: null,
    RBC: null,
    AST: null,
    ALT: null,
    GGT: null,
    BILIRUBIN: null,
    PSA: null,
    GLUCOSE: null,
    INSULIN: null,
    ALBUMIN: null,
    CREATININE: null,
    CHOLESTEROL: null,
    TRIGLYCERIDES: null,
    HDL: null,
    LDL: null,
    'LDL/HDL': null,
    SHBG: null,
    TSH: null,
  },
];

export const NO_AUTH_ANCILLARIES = [
  {
    id: uuid_v4(),
    name: 'Aromasin',
    frequency: 4,
    dose: 12.5,
    date: moment().format('YYYY-MM-DD HH:mm:ssZ'),
    start_wk: 1,
    stop_wk: 16,
  },
  {
    id: uuid_v4(),
    name: 'HGH',
    frequency: 3,
    dose: 6,
    date: moment().format('YYYY-MM-DD HH:mm:ssZ'),
    start_wk: 1,
    stop_wk: 16,
  },
  {
    id: uuid_v4(),
    name: 'HGH',
    frequency: 5,
    dose: 10,
    date: moment().format('YYYY-MM-DD HH:mm:ssZ'),
    start_wk: 1,
    stop_wk: 16,
  },
  {
    id: uuid_v4(),
    name: 'Cabergoline',
    frequency: 7,
    dose: 0.25,
    date: moment().format('YYYY-MM-DD HH:mm:ssZ'),
    start_wk: 4,
    stop_wk: 16,
  },
];

export const NO_AUTH_PEPTIDES = [
  {
    id: uuid_v4(),
    name: 'BPC-157',
    frequency: 1,
    dose: 500,
    date: moment().format('YYYY-MM-DD HH:mm:ssZ'),
    start_wk: 1,
    stop_wk: 16,
  },
  {
    id: uuid_v4(),
    name: 'TB-500',
    frequency: 3,
    dose: 2,
    date: moment().format('YYYY-MM-DD HH:mm:ssZ'),
    start_wk: 1,
    stop_wk: 16,
  },
  {
    id: uuid_v4(),
    name: 'HGH',
    frequency: 1,
    dose: 6,
    date: moment().format('YYYY-MM-DD HH:mm:ssZ'),
    start_wk: 1,
    stop_wk: 16,
  },
  {
    id: uuid_v4(),
    name: 'PEG-MGF',
    frequency: 3.5,
    dose: 500,
    date: moment().format('YYYY-MM-DD HH:mm:ssZ'),
    start_wk: 4,
    stop_wk: 16,
  },
];
